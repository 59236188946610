<template>
<div>
    <v-container class="">

      <GenericRequestsTable 
        ref="requestsTable" 
      />

      <Snackbar 
        :snackbarDialog="snackbar"
        :snackbarText="snackbarText"
        :snackbarColor="snackbarColor"
        @closeSnackbar="closeSnackbar()"
        @errorCreatingRequest="showSnackbarError()"
      />
    </v-container>
  </div>
</template>

<script>
  
    import GenericRequestsTable from '@/components/PaymentRequests/GenericRequestsTable.vue';
    import Snackbar from '@/components/Snackbars/TheSnackbar'
    
    export default {
      name: 'Payments',
      components: {
        GenericRequestsTable,
        Snackbar
      },
      data() {
        return {
          snackbar: false,
          snackbarText: '',
          snackbarColor: '',
        }
      },
      methods: {
        refreshRequestData(msg) {
          this.snackbar = true
          this.snackbarText = msg
          this.snackbarColor = 'green'
          this.$refs.requestsTable.getPaymentRequestDetails() // calling method in table component to refresh data
        },
        showSnackbarError() {
          this.snackbar = true
          this.snackbarText = 'Error wilst creating request. Please try again.'
          this.snackbarColor = 'red'
        },
        closeSnackbar() {
          this.snackbar = false
          this.snackbarText = ''
          this.snackbarColor = ''
        },
      }
    }
    </script>
    
    <style>
    
    </style>
    